<template>
  <div class="wrapper">
    <!-- 添加从业人员页面 -->
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px" style="overflow-x:hidden;">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="personName">
            <el-input v-model="form.personName" placeholder="请输入人员姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="personSex">
            <el-select v-model="form.personSex" placeholder="请选择性别" style="width: 100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证" prop="personIdNum">
            <el-input v-model="form.personIdNum" placeholder="请输入身份证" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="学历" prop="education">
            <el-input v-model="form.education" placeholder="请输入学历" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属单位">
            <el-input v-model="form.affiliatedUnit" placeholder="请输入所属单位" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从业资质">
            <el-input v-model="form.professionalQualification" placeholder="请输入从业资质" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="配送费用" prop="deliveryFee">
            <el-input v-model="form.deliveryFee" placeholder="请输入配送费用" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="人员日期" prop="personBithDay">
            <el-date-picker
              v-model="form.personBithDay"
              popper-class="pickerClass"
              value-format="yyyy-MM-dd"
              type="datetime"
              placeholder="选择日期时间"
              style="width:100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="personTel">
            <el-input v-model="form.personTel" placeholder="请输入联系电话" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off" />
            <!-- <el-select v-model="form.area" placeholder="请选择区县" @change="areaChange" style="width: 100%">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label-width="0" prop="street">
            <el-select v-model="form.street" placeholder="请选择街道" style="width: 100%">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off"></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="是否配送" prop="delivery">
            <el-select v-model="form.delivery" placeholder="是否配送" style="width: 100%">
              <el-option v-for="item in deliveryList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20"> -->
      <!-- <el-col :span="8">
          <el-form-item label="余额" prop="balance">
            <el-input v-model="form.balance" placeholder="请输入余额" auto-complete="off"></el-input>
          </el-form-item>
        </el-col> -->

      <!-- </el-row> -->
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="健康证" prop="healthReport">
            <upload-img :limit="1" :upload-lists="healthReportList" @uploadChange="uploadSuccess1" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="核酸检测证明" prop="testCertificate">
            <upload-img :limit="1" :upload-lists="testCertificateList" @uploadChange="uploadSuccess2" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="合同扫描件" prop="scannedContract">
            <upload-img :limit="1" :upload-lists="scannedContractList" @uploadChange="uploadSuccess3" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="保险单" prop="insurancePolicy">
            <upload-img :limit="1" :upload-lists="insurancePolicyList" @uploadChange="uploadSuccess4" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      healthReportList: [],
      testCertificateList: [],
      scannedContractList: [],
      insurancePolicyList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      communityList: [],
      titleName: '',
      form: {
        city: '西安'
      },
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      areaList: [],
      streetList: [],
      plateNoId: '',
      rules: {
        personName: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
        personSex: [{ required: true, message: '请选择人员性别', trigger: 'change' }],
        delivery: [{ required: true, message: '请选择是否配送', trigger: 'change' }],
        personTel: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系电话格式' }
        ],
        // area: [{ required: true, message: '请选择区县', trigger: 'change' }],
        // street: [{ required: true, message: '请选择街道', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        personBithDay: [{ required: true, message: '请选择人员日期', trigger: 'change' }],
        personName: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
        deliveryFee: [{ required: true, message: '请输入配送费用', trigger: 'blur' }],
        balance: [{ required: true, message: '请输入余额', trigger: 'blur' }],
        personIdNum: [
          { required: true, message: '请输入身份证', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' }
        ]
      },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      deliveryList: [
        {
          value: '0',
          label: '是'
        },
        {
          value: '1',
          label: '否'
        }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          // 然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    }
  },
  created() {
    this.initView()
    this.getAreaList()
  },
  methods: {
    ...mapActions(['downloadFile', 'addmealPersonSave', 'updateWorkPersonServe', 'getArealistServe', 'getstrealistServe', 'mealPersonInfo']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },

    // 获取区县list
    getAreaList() {
      this.getArealistServe().then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(val)
      }
    },
    // 获取街道list
    getStreetList(val) {
      const params = {
        areaName: val
      }
      this.getstrealistServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },

    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加从业人员'
      } else {
        this.titleName = '修改从业人员'
        this.itemData = this.$route.query ? JSON.parse(this.$route.query.item) : null

        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'mealPersonInfo'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              address: data.address,
              area: data.area,
              balance: data.balance,
              city: data.city,
              delivery: data.delivery,
              deliveryFee: data.deliveryFee,
              id: this.itemData.id,
              personBithDay: data.personBithDay,
              personIdNum: data.personIdNum,
              personName: data.personName,
              personSex: data.personSex,
              personTel: data.personTel,
              street: data.street,
              education: data.education,
              affiliatedUnit: data.affiliatedUnit,
              professionalQualification: data.professionalQualification,
              healthReport: data.healthReport,
              testCertificate: data.testCertificate,
              scannedContract: data.scannedContract,
              insurancePolicy: data.insurancePolicy
            }
            if (data.city) {
              this.getAreaList(data.city)
            }
            if (data.area) {
              this.getStreetList(data.area)
            }
            if (data.healthReport) {
              this.getFileDetail1(data.healthReport)
            }
            if (data.testCertificate) {
              this.getFileDetail2(data.testCertificate)
            }
            if (data.scannedContract) {
              this.getFileDetail3(data.scannedContract)
            }
            if (data.insurancePolicy) {
              this.getFileDetail4(data.insurancePolicy)
            }
          }
        })
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.healthReportList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.testCertificateList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail3(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.scannedContractList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail4(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.insurancePolicyList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.healthReport = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.testCertificate = data.join(',')
    },
    uploadSuccess3(data) {
      this.form.scannedContract = data.join(',')
    },
    uploadSuccess4(data) {
      this.form.insurancePolicy = data.join(',')
    },
    // 区域选择
    selectArea(data) {
      this.form.teamName = ''
      this.teamByAreaList = ''
      this.getTeamListByArea(data) // 获取队伍
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          const server = this.routeType == 'add' ? 'addmealPersonSave' : 'addmealPersonSave'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
